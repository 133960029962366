import { PropsOf } from '@emotion/react';
import { Required } from 'utility-types';
import {
  CreateSeedAudienceRequest,
  CreateSeedAudienceRequestIntegrationsInnerIntegrationTypeEnum as IntegrationTypeSDK,
  LookalikeAudienceSizesInner,
  SeedAudienceType as SeedAudienceTypeSDK,
} from '@innovationdepartment/proxima-sdk-axios';
import { CellRendererProps, SelectProps, TableProps, Tabs } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'types/integrations';

export type FlavorCategorySchema = {
  id: string;
  name: string;
};

export type GalaxyFlavorSchema = {
  categoryId: string;
  categoryName: string;
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export type GalaxySchema = {
  id: string;
  name: string;
  flavorId: string;
  createdAt: string;
  updatedAt: string;
  flavor: GalaxyFlavorSchema;
  isInternal: boolean;
  allowMultipleAudiences: boolean;
};

export enum BrandLockAudienceOption {
  KeepAudiences = 'keep_audiences',
  RemoveAudiences = 'remove_audiences',
}

export enum AudienceStatus {
  New = 'new',
  Processing = 'processing',
  Ready = 'ready',
}

export enum LookalikeAudienceStatus {
  New = 'New', // Awaiting seed data generation
  Generating = 'Generating', // Seed data is being generated
  Seeding = 'Seeding', // Seed data is being uploaded to the platform
  Delivered = 'Delivered', // Custom audience has been generated and shared with the customer and is ready to be used
  Error = 'Error', // There was an error at any step in the process
  Active = 'Active', // Custom audience has spend increase since last sync
  Inactive = 'Inactive', // Custom audience has no spend increase since last sync
  Deleted = 'Deleted', // Lookalike audience has been deleted
  DeleteFailed = 'Delete Failed', // Delete has been tried and failed in the event for a lookalike audience
  DeletePending = 'Delete Pending', // Delete has started for a lookalike audience and is pending
  /* frontend custom ones */
  Processing = 'Processing', // Custom audience is being processed
}

export enum SeedAudienceStatus {
  New = 'new', // Awaiting seed data generation
  Generating = 'generating', // Seed data is being generated
  Seeding = 'seeding', // Seed data is being uploaded to the platform
  Delivered = 'delivered', // Custom audience has been generated and shared with the customer and is ready to be used
  Error = 'error', // There was an error at any step in the process
  Active = 'active', // Custom audience has spend increase since last sync
  Inactive = 'inactive', // Custom audience has no spend increase since last sync
  Deleted = 'deleted', // Custom audience has been deleted
  DeleteFailed = 'deleteFailed', // Delete has been tried and failed in the event for a custom audience
  DeletePending = 'deletePending', // Delete has started for a custom audience and is pending
  /* frontend custom ones */
  Processing = 'processing', // Custom audience is being processed
}

export type LookalikeAudienceSchema = {
  id: string;
  name: string;
  brandId: string;
  createdByUserId: string;
  integrationType: IntegrationType;
  seedAudienceId: string;
  integrationAudienceId: string;
  size: number;
  externalId?: string;
  importedAt?: string;
  displayStatus: LookalikeAudienceStatus;
  status: string; // TODO(Jenky): get these from the backend
  lifetimeSpend: number;
  targetCountry: string;
  createdAt: string;
  updatedAt: string;
};

type SeedAudienceType = SeedAudienceTypeSDK;

export type SeedAudienceSchema = {
  id: string;
  name: string;
  brandId: string;
  createdByUserId: string;
  type: SeedAudienceType;
  galaxyId: GalaxySchema['id'];
  flavorId: string;
  categoryId: string;
  categoryName: string;
  lifetimeSpend: number;
  updateDayFrequency?: number;
  optedOutOfUpdatesAt?: string;
  status: string;
  lookalikeAudiences: LookalikeAudienceSchema[];
  createdAt: string;
  updatedAt: string;
};

/* lookalike sizes (sources size selection) */
export type LookalikeSizeSchema = LookalikeAudienceSizesInner;

export type FetchSeedAudienceSchema = {
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortDirection: string;
  seedAudiences: SeedAudienceSchema[];
};

export type SpendSummaryDataSchema = {
  lifetimeSpend: number;
  monthlyAverageSpend: number;
};

export type DateRangeSpendDataSchema = {
  totalSpend: number;
  averageDailySpend: number;
};

export type AudienceUpdateSettingsSchema = {
  seedAudiences: {
    [seedAudienceId: string]: boolean;
  };
};

export type AudienceSettingsSchema = {
  seedAudienceId: string;
  optedIntoUpdates: boolean;
  updateDayFrequency: number | string;
};

export type UpdateBrandAudienceSettingsInput = {
  flavorCategoryId: string;
};

/* ------ ---------------- ------ */
/* ------ components types ------ */
/* ------ ---------------- ------ */
export type AudiencesProps = {
  seedAudiences: SeedAudienceSchema[];
  onShowRenameSeedAudienceModal: (row: SeedAudienceSchema) => void;
  onShowRenameLookalikeAudienceModal: (row: LookalikeAudienceSchema) => void;
  onShowAudienceSettingsModal: (row: SeedAudienceSchema) => void;
  onShowCreateSeedAudiencesModal?: (open?: boolean) => void;
  onShowDeleteSeedAudienceModal: (row: SeedAudienceSchema) => void;
  onShowCreateLookalikeAudienceModal: (row: SeedAudienceSchema) => void;
  onShowDeleteLookalikeAudienceModal: (row: LookalikeAudienceSchema) => void;
};

export type AudiencesHeaderProps = {
  onShowCreateSeedAudiencesModal: AudiencesProps['onShowCreateSeedAudiencesModal'];
};

/* seed audience table components props */
export type SeedAudiencesTableProps = {
  seedAudiences: AudiencesProps['seedAudiences'];
  onShowRenameSeedAudienceModal: AudiencesProps['onShowRenameSeedAudienceModal'];
  onShowRenameLookalikeAudienceModal: AudiencesProps['onShowRenameLookalikeAudienceModal'];
  onShowAudienceSettingsModal: AudiencesProps['onShowAudienceSettingsModal'];
  onShowDeleteSeedAudienceModal: AudiencesProps['onShowDeleteSeedAudienceModal'];
  onShowDeleteLookalikeAudienceModal: AudiencesProps['onShowDeleteLookalikeAudienceModal'];
  onShowCreateLookalikeAudienceModal: AudiencesProps['onShowCreateLookalikeAudienceModal'];
};

export type SeedAudiencesTableViewProps = {
  seedAudiences: AudiencesProps['seedAudiences'];
  config: TableProps<SeedAudienceSchema>['config'];
};

/* lookalike audience table component props */
export type LookalikeAudiencesTableProps = {
  lookalikeAudiences: LookalikeAudienceSchema[];
  onShowRenameLookalikeAudienceModal: (row: LookalikeAudienceSchema) => void;
  onShowDeleteLookalikeAudienceModal: (row: LookalikeAudienceSchema) => void;
};

export type LookalikeAudiencesTableViewProps = Omit<
  LookalikeAudiencesTableProps,
  'onShowRenameLookalikeAudienceModal' | 'onShowDeleteLookalikeAudienceModal'
> & {
  config: TableProps<LookalikeAudienceSchema>['config'];
};

/* props for renderer function/singular cell components */
export type SeedAudienceTableColumnHeader =
  | 'seedAudience'
  | 'displayStatus'
  | 'dateCreated'
  | 'actions'
  | 'isHovered';
type SeedAudienceTableRowProperties = { [key in SeedAudienceTableColumnHeader]: true };

/* props for expand renderer function/singular cell components */
export type LookalikeAudienceTableColumnHeader =
  | 'lookalikeAudience'
  | 'platform'
  | 'displayStatus'
  | 'dateCreated'
  | 'actions'
  | 'isHovered';
type LookalikeAudienceTableRowProperties = { [key in LookalikeAudienceTableColumnHeader]: true };

export type LookalikeAudienceTableRowExtraFunctions = {
  onShowRenameLookalikeAudienceModal: LookalikeAudiencesTableProps['onShowRenameLookalikeAudienceModal'];
  onShowDeleteLookalikeAudienceModal: LookalikeAudiencesTableProps['onShowDeleteLookalikeAudienceModal'];
};

export type SeedAudienceTableRowExtraFunctions = {
  onShowRenameSeedAudienceModal: SeedAudiencesTableProps['onShowRenameSeedAudienceModal'];
  onShowAudienceSettingsModal: SeedAudiencesTableProps['onShowAudienceSettingsModal'];
  onShowDeleteSeedAudienceModal: SeedAudiencesTableProps['onShowDeleteSeedAudienceModal'];
  onShowCreateLookalikeAudienceModal: SeedAudiencesTableProps['onShowCreateLookalikeAudienceModal'];
};

export type SeedAudienceTableRowProps = SeedAudienceSchema & SeedAudienceTableRowProperties;

export type LookalikeAudienceTableRowProps = LookalikeAudienceSchema &
  Omit<LookalikeAudienceTableRowProperties, 'displayStatus'>;

export type AudienceTableCellRendererProps<T> = CellRendererProps<T>;

export type RenameAudienceModalProps = {
  seedAudience?: SeedAudienceSchema;
  lookalikeAudience?: LookalikeAudienceSchema;
  open: boolean;
  onClose: () => void;
  onRenameAudience: (name: string) => void;
};

export type DeleteAudienceModalProps = {
  seedAudience?: SeedAudienceSchema;
  lookalikeAudience?: LookalikeAudienceSchema;
  open: boolean;
  onClose: () => void;
  onDeleteAudience: () => void;
};

export type AudienceSettingsModalProps = {
  seedAudience?: SeedAudienceSchema;
  open: boolean;
  onClose: () => void;
  onAudienceSettingsUpdate: (audienceSettings: AudienceSettingsSchema) => void;
};

export type AudienceSettingsModalViewProps = {
  onClose: () => void;
  isSubmitting: boolean;
};

/** CreateSeedAudiencesModalView */
export type CreateSeedAudienceSchema = CreateSeedAudienceRequest;

export type CreateSeedAudiencesFormSchema = {
  lookalikeSize: number;
  galaxies: GalaxySchema[];
  integrationType: IntegrationTypeSDK;
  names: Required<CreateSeedAudienceSchema, 'names'>['names'];
  referenceBrandProportionTarget: Required<
    CreateSeedAudienceSchema,
    'referenceBrandProportionTarget'
  >['referenceBrandProportionTarget'];
};

export type Tab = 'categoryShoppers' | 'colal';

export type CreateSeedAudiencesModalProps = {
  show: boolean;
  galaxies: GalaxySchema[];
  onClose: () => void;
  onSeedAudiencesCreation: () => void;
};

export type CreateSeedAudiencesModalViewProps = CreateSeedAudiencesModalProps & {
  tab: Tab;
  tabs: PropsOf<typeof Tabs>['tabs'];
  onTabChange: (nextTab: Tab) => void;
};

/* Category Shoppers tab */
export type CategoryShoppersProps = CreateSeedAudiencesModalProps & { tab: 'categoryShoppers' };

export type CategoryShoppersViewProps = CategoryShoppersProps & {
  canCreate: boolean;
  loading: boolean;
  submitting: boolean;
  flavorCategory?: FlavorCategorySchema;
};

/* COLAL tab */
export type COLALProps = CreateSeedAudiencesModalProps & { tab: 'colal' };

export type COLALViewProps = COLALProps & {
  canCreate: boolean;
  loading: boolean;
};

export type CreateSeedAudiencesCOLALFormSchema = {
  dataSource: 'original' | 'alternative';
  brandId: string;
  audienceDefaultName: string;
  lookalikeSize: number;
  galaxies: GalaxySchema[];
  integrationType: IntegrationTypeSDK;
  names: Required<CreateSeedAudienceSchema, 'names'>['names'];
  referenceBrandProportionTarget: Required<
    CreateSeedAudienceSchema,
    'referenceBrandProportionTarget'
  >['referenceBrandProportionTarget'];
};

/* Layout props */
export type CreateSeedAudienceCommonLayoutProps = {
  tab: CreateSeedAudiencesModalViewProps['tab'];
  canCreate: CategoryShoppersViewProps['canCreate'];
  flavorCategory?: FlavorCategorySchema;
  loading: boolean;
};

export type CreateSeedAudiencesDataSourceProps = CreateSeedAudienceCommonLayoutProps;

export type CreateSeedAudiencesNamesProps = CreateSeedAudienceCommonLayoutProps;

export type CreateSeedAudiencesLookalikeSizeProps = {
  disabled?: boolean;
  loading: boolean;
  sizes?: number[];
};

export type CreateSeedAudiencesLookalikeSizeViewProps = Omit<
  CreateSeedAudiencesLookalikeSizeProps,
  'sizes'
> & {
  options: SelectProps['options'];
};

/* CreateLookalikeAudienceModal */
export type CreateLookalikeAudienceSchema = {
  seedAudienceId: string;
  integrationType: IntegrationType;
  size: number;
};

export type CreateLookalikeAudienceModalProps = {
  open: boolean;
  onCreateLookalikeAudience: (data: CreateLookalikeAudienceSchema) => Promise<void>;
  onClose: () => void;
  seedAudience?: SeedAudienceSchema;
};

export type CreateNewLookalikeModalViewProps = Omit<
  CreateLookalikeAudienceModalProps,
  'onCreateLookalikeAudience'
> & {
  isSubmitting: boolean;
  loading: boolean;
  sizes: LookalikeSizeSchema[];
};

export type LookalikeSizePreviewProps = {
  galaxy: GalaxySchema;
  size?: number;
};

export type RetryLookalikeAudienceSchema = {
  lookalikeAudienceId: string;
  seedAudienceId: string;
};

/* tbd */
