import { Dictionary } from 'lodash';

export enum IntegrationType {
  Facebook = 'facebook',
  Shopify = 'shopify',
  TikTok = 'tiktok',
}

export enum BrandIntegrationStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Incomplete = 'incomplete',
  TermsRequired = 'terms-required',
  NeverConnected = 'never-connected',
}

export type Integration = {
  integrationId: string;
  createdByUserId: string;
  brandId: string;
  type: IntegrationType;
  status: IntegrationStatus;
  metaData: Record<string, string>;
  lastUpdateAt: string;
};

export enum IntegrationStatus {
  Active = 'active',
  Expired = 'expired',
  Unauthorized = 'unauthorized',
  // Deleted = 'deleted',
}

export enum IntegrationListStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Incomplete = 'incomplete',
  // non-started meaning that it is a first-timer and has not started the integration process
  // for that particular integration (only FB for now)
  NonStarted = 'non-started',
}

export type BrandListIntegrationProps = {
  loading: boolean;
  isAdAccountActive: boolean;
  brandId: string;
  integrations: Integration[];
};

export type BrandListIntegrationStatusProps = {
  brandId: string;
  isAdAccountActive?: boolean;
  type: Exclude<IntegrationType, 'tiktok'>;
  integrations: Dictionary<Integration[]>; // from lodash
  includeIndicator?: boolean;
};
