export const allowedKeys = ['name', 'accountId', 'integrationId', 'status'] as const;

export enum AdAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type AdAccountStore = {
  adAccount: AdAccount | null;
  clear: () => void;
  update: (adAccount: Partial<AdAccount>) => void;

  adAccountsList: AdAccount[];
  getActiveAdAccountFromList: () => AdAccount | undefined;
  updateAdAcountsList: (adAccounts: AdAccount[]) => void;
};

type Keys = (typeof allowedKeys)[number];

export type AdAccount = Omit<{ [key in Keys]: string }, 'status'> & { status: AdAccountStatus };
