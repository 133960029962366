import {
  ShopifySmallGray,
  ShopifySmall,
  MetaSmall,
  MetaSmallGray,
} from '@innovationdepartment/proxima-ui';
import { ReactNode } from 'react';
import {
  BrandListIntegrationStatusProps,
  IntegrationListStatus,
  IntegrationStatus,
  IntegrationType,
} from 'types/integrations';

const icons: { [key in IntegrationType]: [ReactNode, ReactNode] } = {
  shopify: [<ShopifySmall />, <ShopifySmallGray />],
  facebook: [<MetaSmall />, <MetaSmallGray />],
  tiktok: [null, null], // TODO: add tiktok icons
};

function useBrandListIntegrationStatus(props: BrandListIntegrationStatusProps) {
  const { integrations, type, isAdAccountActive } = props;

  const isShopify = type === IntegrationType.Shopify;
  const isFacebook = type === IntegrationType.Facebook;

  const integrationsOfType = integrations[type] ?? [];

  let status: IntegrationListStatus = IntegrationListStatus.NonStarted;
  let tooltipContent: React.ReactNode = 'Not yet connected';
  const isConnected = integrationsOfType.some((i) => i.status === IntegrationStatus.Active);
  const isDisconnected = !isConnected && integrationsOfType.length > 0;

  if (isShopify) {
    const setToConnectedStatus = isConnected;
    const setToDisconnectedStatus = isDisconnected;

    if (setToConnectedStatus) {
      status = IntegrationListStatus.Connected;
      tooltipContent = 'Connected';
    }

    if (setToDisconnectedStatus) {
      status = IntegrationListStatus.Disconnected;
      tooltipContent = 'Disconnected';
    }
  }

  if (isFacebook) {
    const setToCompleteStatus = isConnected && isAdAccountActive;
    const setToIncompleteStatus = isConnected;
    const setToDisconnectedStatus = isDisconnected;

    if (setToIncompleteStatus) {
      status = IntegrationListStatus.Incomplete;
      tooltipContent = 'Incomplete';
    }
    if (setToCompleteStatus) {
      status = IntegrationListStatus.Connected;
      tooltipContent = 'Connected';
    }
    if (setToDisconnectedStatus) {
      status = IntegrationListStatus.Disconnected;
      tooltipContent = 'Disconnected';
    }
  }

  const [iconConnected, iconDisconnected] = icons[type];

  const icon = isConnected ? iconConnected : iconDisconnected;

  return {
    icon,
    status,
    tooltipContent,
  };
}

export default useBrandListIntegrationStatus;
