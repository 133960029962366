import { Link, useNavigate } from 'react-router-dom';
import {
  NavBar as ProximaNavBar,
  NavBarButton,
  DashboardIcon,
  ManageIcon,
  ErrorWarning,
  Group as AudiencesIcon,
  colors,
  ProximaLogoNova,
} from '@innovationdepartment/proxima-ui';
import { Insights } from '@mui/icons-material';

import { IUser } from '@innovationdepartment/proxima-ui/dist/esm/types/user';
import { useAuth0 } from '@auth0/auth0-react';
import { useBrandStore } from 'stores';
import { useBackgroundConditions } from 'hooks';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const NAVBAR_COLORS = {
  logo: colors.legacy.offWhite,
  bg: colors.legacy.tealDarkest,
  bgHover: colors.legacy.tealDarker,
  color: colors.legacy.mistDarker,
  colorHover: colors.legacy.offWhite,
};

const NavBar = () => {
  const { user, logout } = useAuth0();
  const { brand, clearBrandStore } = useBrandStore();
  const { showNavbar } = useBackgroundConditions();
  const navigate = useNavigate();

  if (!showNavbar) return null;

  const isButtonActive = (buttonPathname: string) => window.location.pathname === buttonPathname;

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
    clearBrandStore();
  };

  if (!user) return null;

  const userDetails: IUser = {
    firstName: user.given_name as string,
    lastName: user.family_name as string,
    email: user.email as string,
  };

  const navbarButtons = [
    {
      label: 'Overview',
      pathname: `/brand/${brand.brandId}`,
      icon: <DashboardIcon />,
    },
    {
      button: { icon: <Insights />, label: 'Insights' },
      isActive: false,
      drawerItems: {
        items: [
          {
            items: [
              {
                label: 'Meta Performance',
                pathname: `/brand/${brand.brandId}/insights/trends`,
              },
            ],
            label: 'TRENDS',
          },
          {
            items: [
              {
                label: 'Meta Benchmarks',
                pathname: `/brand/${brand.brandId}/insights/chart`,
              },
            ],
            label: 'BENCHMARKS',
          },
        ],
        onClick: (pathname: string) => navigate(pathname),
        title: 'Insights',
      },
    },
    {
      label: 'Audiences',
      pathname: `/brand/${brand.brandId}/audiences`,
      icon: <AudiencesIcon />,
    },
    {
      label: 'Manage',
      pathname: `/brand/${brand.brandId}/manage`,
      icon: <ManageIcon />,
    },
  ];

  const config = {
    logo: (
      <Link to="/brands">
        <ProximaLogoNova />
      </Link>
    ),
    accountMenuItems: [
      {
        label: 'Logout',
        pathname: '',
        onClick: onLogout,
      },
    ],
    brandColors: NAVBAR_COLORS,
  };

  const navbarBottomButtons = [
    {
      key: 'warnings',
      component: (
        <StyledLink to={`/brand/${brand.brandId}/warnings`}>
          <ErrorWarning />
        </StyledLink>
      ),
    },
  ];

  return (
    <ProximaNavBar
      logo={config.logo}
      accountMenuItems={config.accountMenuItems}
      pathname={window.location.pathname}
      user={userDetails}
      navbarMenuItems={navbarBottomButtons}
    >
      {navbarButtons.map((button) => {
        const hasDrawer = button.button;

        if (hasDrawer)
          return (
            <NavBarButton
              button={button.button}
              isActive={button.isActive}
              key={button.button.label}
              drawerItems={button.drawerItems}
            />
          );

        const isActive = isButtonActive(button.pathname);

        return (
          <StyledLink to={button.pathname} key={button.label}>
            <NavBarButton button={button} isActive={isActive} />
          </StyledLink>
        );
      })}
    </ProximaNavBar>
  );
};

export default NavBar;
