import { PerformanceAdsViewProps } from 'components/PerformanceAds/types';
import { BaseTable } from '@innovationdepartment/proxima-fbtable';
import { PERFORMANCE_ADS_COLUMN_DEFINITIONS } from './definitions';
import cellRenderer from './performanceAdsCellRender';

const PerformanceAdsTable = (props: PerformanceAdsViewProps) => {
  const { ads, loaded, loading, onRequestNext } = props;

  return (
    <BaseTable
      onInfiniteScroll={loaded ? onRequestNext : undefined}
      columns={PERFORMANCE_ADS_COLUMN_DEFINITIONS}
      data={ads.data}
      summary={ads.summary}
      loading={loading}
      cellRender={cellRenderer}
    />
  );
};

export default PerformanceAdsTable;
