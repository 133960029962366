import { IExposedBaseTableProps } from '@innovationdepartment/proxima-fbtable';
import { CreativeAd } from './types';
import { formatCurrency } from 'utils';
import PerformanceAdsTableFooterRender from './PerformanceAdsTable.FooterRender';
import pluralize from 'pluralize';

export const PERFORMANCE_ADS_COLUMN_DEFINITIONS: IExposedBaseTableProps<CreativeAd>['columns'] = [
  {
    accessor: 'name',
    Header: 'Ad Name',
    Footer: (props: any) => {
      const { data = [] } = props;
      const totalAds = data.length;
      return (
        <PerformanceAdsTableFooterRender
          value={`Results: ${totalAds} ${pluralize('ads', totalAds)}`}
        />
      );
    },
  },
  {
    accessor: 'brand',
    Header: 'Brand',
  },
  {
    accessor: 'category',
    Header: 'Category',
  },
  {
    accessor: 'configuredStatus',
    Header: 'Status',
  },
  {
    accessor: 'launchDate',
    Header: 'Launch Date',
  },
  {
    accessor: 'spendDays',
    Header: 'Days w/Spend',
  },
  {
    accessor: 'lifetimeSpend',
    Header: 'Lifetime Spend',
    Footer: (props: any) => {
      const { data } = props;
      const lifetimeSpend = (data as CreativeAd[]).reduce(
        (acc, ad) => acc + +(ad.lifetimeSpend ?? 0),
        0,
      );

      return (
        <PerformanceAdsTableFooterRender
          label="Total Spend"
          value={formatCurrency(lifetimeSpend)}
          alignment="right"
        />
      );
    },
  },
  {
    accessor: 'purchaseRoasCategoryQuentile',
    Header: 'ROAS (Category) Quentile',
  },
  {
    accessor: 'ctrCategoryQuentile',
    Header: 'CTR (Category) Quentile',
  },
  // {
  //   accessor: 'cpaCategoryQuentile',
  //   Header: 'CPA (Category) Quentile',
  // },
  {
    accessor: 'purchaseRoasBrandIdQuentile',
    Header: 'ROAS (Brand) Quentile',
  },
  {
    accessor: 'ctrBrandIdQuentile',
    Header: 'CTR (Brand) Quentile',
  },
  // {
  //   accessor: 'cpaBrandQuentile',
  //   Header: 'CPA (Brand) Quentile',
  // },
  {
    accessor: 'adset',
    Header: 'Attribution Window',
  },
  {
    accessor: 'spend',
    Header: 'Spend',
    Footer: (props: any) => {
      const { data } = props;
      const totalSpend = (data as CreativeAd[]).reduce((acc, ad) => acc + +(ad.spend ?? 0), 0);
      return (
        <PerformanceAdsTableFooterRender
          label="Total Spend"
          value={formatCurrency(totalSpend)}
          alignment="right"
        />
      );
    },
  },
];
