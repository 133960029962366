const formatNumberToThousands = (num: number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
  }).format(num);

const formatNumberToCurrency = (num: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(num);

const formatNumberToCommaSeparated = (num: number) =>
  new Intl.NumberFormat('en-US', {
    notation: 'standard',
  }).format(num);

export { formatNumberToThousands, formatNumberToCurrency, formatNumberToCommaSeparated };
