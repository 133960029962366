import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { LookalikeAudienceTableRowProps, AudienceTableCellRendererProps } from 'types/audiences';
import { status as statusUtils } from './utils';
import VerticalWrapper from './AudienceRowCell.VerticalWrapper';

const StatusBadge = styled.div<{ color: string }>`
  align-self: flex-start;
  padding: 0 6px;
  border-radius: 10px;
  background-color: ${({ color }) => color};
`;

const AudienceRowCellLookalikeStatus = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => {
  // @ts-ignore
  const color = statusUtils.color[row?.displayStatus] ?? statusUtils.color.default;

  return (
    <VerticalWrapper>
      <StatusBadge color={color}>
        <Text variant="tag2Medium" color="neutral80">
          {row?.displayStatus}
        </Text>
      </StatusBadge>
    </VerticalWrapper>
  );
};

export default AudienceRowCellLookalikeStatus;
