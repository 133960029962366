import { IExposedBaseTableProps } from '@innovationdepartment/proxima-fbtable';
import { CreativeAd } from './types';
import { OkCheck } from '../elements/OkCheck';
import BadCheck from '../elements/BadCheck';
import styled from '@emotion/styled';
import { Text } from '@innovationdepartment/proxima-ui';
import { formatDate, formatQuentile } from 'utils';
import { formatNumberToCommaSeparated, formatNumberToCurrency } from 'utils/formatNumber';

type CellRenderer = IExposedBaseTableProps<CreativeAd>['cellRender'];

const RenderBase = styled.div`
  padding: 4px 0;
  width: 100%;
  overflow: hidden;
`;

const RenderCenter = styled(RenderBase)`
  text-align: center;
`;

const RenderRight = styled(RenderBase)`
  text-align: right;
`;

const RenderElement = ({
  value,
  Element,
}: {
  value: string | React.ReactNode;
  Element: typeof RenderBase;
}) => (
  <Element>
    <Text ellipsis variant="table1">
      {value}
    </Text>
  </Element>
);

const performanceAdsCellRender: CellRenderer = (props) => {
  const { column, value } = props;

  switch (column as keyof CreativeAd) {
    /* quentiles columns */
    case 'purchaseRoasCategoryQuentile':
    case 'ctrCategoryQuentile':
    case 'purchaseRoasBrandIdQuentile':
    case 'ctrBrandIdQuentile':
      return (
        <RenderElement value={formatQuentile(value as unknown as number)} Element={RenderRight} />
      );
    case 'cpaCategoryQuentile':
    case 'cpaBrandIdQuentile':
      return (
        <RenderElement
          value={formatQuentile(value as unknown as number, true)}
          Element={RenderRight}
        />
      );
    case 'brand':
      return (
        <RenderElement
          value={(value as unknown as CreativeAd['brand'])?.name as string}
          Element={RenderBase}
        />
      );
    case 'launchDate':
      return <RenderElement value={formatDate(value)} Element={RenderRight} />;
    case 'spendDays':
      return (
        <RenderElement value={formatNumberToCommaSeparated(+(value ?? 0))} Element={RenderRight} />
      );
    case 'lifetimeSpend':
      return <RenderElement value={formatNumberToCurrency(+(value ?? 0))} Element={RenderRight} />;
    case 'spend':
      return <RenderElement value={formatNumberToCurrency(+(value ?? 0))} Element={RenderRight} />;
    case 'adset':
      return (
        <RenderElement
          value={(value as unknown as CreativeAd['adset'])?.attributionWindows as string}
          Element={RenderBase}
        />
      );
    case 'configuredStatus':
      return (
        <RenderElement
          value={value === 'ACTIVE' ? <OkCheck /> : <BadCheck />}
          Element={RenderCenter}
        />
      );
    default:
      break;
  }

  return undefined;
};

export default performanceAdsCellRender;
