import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors, Text, StarYellowSmall, UserPlaceholder } from '@innovationdepartment/proxima-ui';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { Brand } from 'types/stores/brand-store';
import { Integration } from 'types/integrations';
import BrandListFavoriteAction from './BrandList.FavoriteAction';
import BrandListIntegrations from './BrandListIntegrations';
import Action from './Actions/Action';
import pluralize from 'pluralize';
import { differenceInHours } from 'date-fns';
import { formatCurrency, formatDateFromIso } from 'utils';
import { PlanFilterOptions } from 'types/brandList';
import { SubscriptionStatusEnum } from '@innovationdepartment/proxima-sdk-axios';

type CellRenderer = (args: {
  row: Brand & {
    isSelectedBrand?: boolean;
    refreshFavorites?: () => void;
    confirmDeleteBrand: (brandId: string) => void;
    confirmLockBrand: (brandId: string) => void;
    confirmUnlockBrand: (brandId: string) => void;
    favorites?: Brand[];
    status?: string;
    integrations: Integration[];
    loading: boolean;
    isAdAccountActive: boolean;
    plan: string;
    dailySpend: number;
    lastSpend: string;
    trialEndDate: string;
    billingStatus: string;
  };
  header: string;
  cell: any;
  isHovering: boolean;
  index: number;
}) => Element | number | string;

const selectedRowAvatarBorder = `0 0 0 2px ${colors.white}, 0 0 0 6px ${colors.neutral40}`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const BrandNameCell = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  min-width: 620px;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  & > div:first-of-type {
    box-shadow: ${(props) => (props.selected ? selectedRowAvatarBorder : 'none')};
  }
  & > :nth-of-type(2) {
    flex: 1;
  }
`;

const AvatarWrapper = styled.div<{ selected: boolean }>`
  border-radius: 4px;
  position: relative;
  height: 32px;
  & > svg {
    border-radius: ${(props) => (props.selected ? 4 : 2)}px;
  }
`;

const Capitalize = styled.span<{ bold: boolean }>`
  text-transform: capitalize;
  font-weight: ${(props) => (props.bold ? 'bold' : 'inherit')};
`;

const FavoriteMarkerContainer = styled.div`
  background-color: ${colors.white};
  display: grid;
  place-content: center;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  position: absolute;
  bottom: -8px;
  right: -8px;
`;

const LockIconWrapper = styled.span`
  color: ${colors.neutral60};
  height: 16px;
  vertical-align: middle;
  padding-left: 3px;
  width: 16px;
`;

const favoriteMarker = (
  <FavoriteMarkerContainer>
    <StarYellowSmall />
  </FavoriteMarkerContainer>
);

const brandRowRender: CellRenderer = ({ header, cell, row, isHovering }) => {
  const {
    favorites,
    brandId,
    integrations,
    loading,
    isAdAccountActive,
    status,
    plan,
    trialEndDate,
    dailySpend,
    lastSpend,
    billingStatus,
  } = row;

  const isFavorite = !!favorites?.find((favorite: Brand) => favorite.brandId === brandId);

  if (typeof cell === 'function') {
    return cell();
  }

  const linkTo = `/brand/${brandId}`;

  if (header === 'name') {
    const isSelected = row?.isSelectedBrand as boolean;
    return (
      <UnstyledLink to={linkTo}>
        <BrandNameCell selected={isSelected}>
          <AvatarWrapper selected={isSelected}>
            <UserPlaceholder />
            {isFavorite && favoriteMarker}
          </AvatarWrapper>
          <div>
            <Text color="neutral100" variant="table2">
              <Capitalize bold={isSelected}>{cell}</Capitalize>
            </Text>
            {status === 'locked' && (
              <LockIconWrapper>
                <LockOutlinedIcon />
              </LockIconWrapper>
            )}
          </div>
          <BrandListFavoriteAction
            isFavorite={isFavorite}
            refreshFavorites={() => row?.refreshFavorites?.()}
            brandId={brandId}
          />
        </BrandNameCell>
      </UnstyledLink>
    );
  }

  if (header === 'integration') {
    return (
      <UnstyledLink to={linkTo}>
        <BrandListIntegrations
          brandId={brandId}
          integrations={integrations}
          isAdAccountActive={isAdAccountActive} // prop only works for Facebook
          loading={loading}
        />
      </UnstyledLink>
    );
  }

  if (header === 'plan') {
    const showNoPlan = billingStatus === SubscriptionStatusEnum.Canceled;
    const trialEndDateConverted = new Date(trialEndDate);
    const diffInHours = differenceInHours(trialEndDateConverted, new Date());
    const daysRemaining = Math.ceil(diffInHours / 24);
    const daysRemainingText = pluralize('days', daysRemaining, true);

    return (
      <>
        {showNoPlan || !plan ? (
          <Text variant="body2" color="neutral50">
            No plan
          </Text>
        ) : (
          <>
            <Text variant="body2">
              <Capitalize bold={false}> {plan} </Capitalize>
            </Text>
            {trialEndDate && plan === PlanFilterOptions.Trial && (
              <Text variant="body3" color="neutral50">
                {daysRemainingText} left
              </Text>
            )}
          </>
        )}
      </>
    );
  }

  if (header === 'dailySpend') {
    if (!dailySpend || dailySpend <= 0) return null;
    const roundedSpend = Math.round(dailySpend * 100) / 100;
    return <Text variant="body2"> {formatCurrency(roundedSpend)} </Text>;
  }

  if (header === 'lastSpend' && lastSpend) {
    const formattedLastSpend = formatDateFromIso(lastSpend);
    return <Text variant="body2"> {formattedLastSpend} </Text>;
  }

  if (header === 'action') {
    return (
      <Action
        isHovering={isHovering}
        brandInfo={{ brandId, status: status || '' }}
        showMenu
        confirmDeleteBrand={row.confirmDeleteBrand}
        confirmLockBrand={row.confirmLockBrand}
        confirmUnlockBrand={row.confirmUnlockBrand}
      />
    );
  }

  return cell;
};

export default brandRowRender;
