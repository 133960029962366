import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Brand, BrandStatusEnum } from '@innovationdepartment/proxima-sdk-axios';
import { TableData, useToaster } from '@innovationdepartment/proxima-ui';
import { useBrandStore } from 'stores';
import { useBrandElasticSearchApi, useProximaSDK, useShowSpinner, useFeatureFlag } from 'hooks';
import { debounce } from 'lodash';

import {
  BrandListIntegrations,
  BrandListProps,
  BrandTab,
  PlanFilterOptions,
} from 'types/brandList';
import { Integration } from 'types/integrations';
import { AdAccountBatch } from 'types/hooks/useAdManager';
import { BrandLockAudienceOption } from 'types/audiences';

import BrandListView from './BrandList.View';
import brandRowRender from './brandRowRender';
import DeleteConfirmation from './Actions/DeleteConfirmation.Container';
import LockConfirmation from './Actions/LockConfirmation/LockAccess.Container';
import UnlockConfirmation from './Actions/UnlockConfirmation/UnlockConfirmation.Container';
import { useNavigate } from 'react-router-dom';

const MAX_CHARS_FOR_SEARCH = 2;

const debouncer = debounce((cb) => cb(), 1000);

// TODO(Jenky): This should be moved inside the component
// TODO(Jenky): update the types properly, see LookalikeAudienceTable.Container.tsx in proxima.web
const brandListConfig: any = {
  cellRenderer: brandRowRender,
  columns: {
    order: ['name', 'plan', 'dailySpend', 'lastSpend', 'integration', 'action'], // TODO(Jenky): add lastUpdatedAt when API is updated
    columnId: 'brandId',
    name: { label: 'Brand Name' },
    plan: { label: 'Plan' },
    dailySpend: { label: 'Daily Proxima spend' },
    lastSpend: { label: 'Last Proxima spend' },
    integration: { label: 'Integration' },
    action: {
      label: 'Actions',
      align: 'right',
      hideLabel: true,
      width: 50,
    },
    lastUpdateAt: { label: 'Date Joined', width: 240 },
  },
};

const tabs: BrandListProps<TableData>['tabs'] = [
  { label: 'All brands', key: 'all' },
  { label: 'My brands', key: 'favorites' },
];

/** TODO(Jenky): Break down this component into smaller components... this is hard to go around with new logic */
const BrandList = () => {
  const brandTableRef = useRef<HTMLDivElement>(null);
  const {
    loading: brandsLoading,
    getNextBrandBatch,
    getBrands,
    getPreviousBrandBatch,
    hasNext,
    hasPrevious,
  } = useBrandElasticSearchApi();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userId = user?.sub;
  const [searchDirty, setSearchDirty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [brandStatusUpdating, setBrandStatusUpdating] = useState(false);
  const userFavoriteApi = useProximaSDK('UsersApi');
  const brandsApi = useProximaSDK('BrandsApi');
  const integrationsApi = useProximaSDK('IntegrationsApi');
  const fbIntegrationsApi = useProximaSDK('FbIntegrationApi');
  const { performanceLibrary } = useFeatureFlag();
  const { brand } = useBrandStore();
  // temporary solution to get integrations until a better solution is thought of (Jenky)
  const [integrations, setIntegrations] = useState<BrandListIntegrations>({
    all: [],
    favorites: [],
  });
  const { showToaster } = useToaster();
  const [brandIdToDelete, setBrandIdToDelete] = useState('');
  const [brandIdToLock, setBrandIdToLock] = useState('');
  const [brandIdToUnlock, setBrandIdToUnlock] = useState('');
  const [filterBrandName, setFilterBrandName] = useState<string>('');
  const [filteredPlans, setFilteredPlans] = useState<string[]>([]);
  const [filteredSpend, setFilteredSpend] = useState<string[]>([]);
  const [filterStateCounter, setFiltersStateCounter] = useState<number>(0);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [favorites, setFavorites] = useState<Brand[]>([]);
  const [adAccounts, setAdAccounts] = useState<AdAccountBatch>([]);
  const [favoriteAdAccounts, setFavoriteAdAccounts] = useState<AdAccountBatch>([]);

  const [tab, setTab] = useState<BrandTab>('all');
  const isMounted = useRef(false);
  const hasSelectedFilters = filteredPlans.length > 0 || filteredSpend.length > 0;

  const findBrandToUpdate = (brandId: string) => {
    const favoritesAndBrands = {
      all: brands,
      favorites,
    }[tab];
    const brandToUpdate = favoritesAndBrands.find((b) => b.brandId === brandId);
    return brandToUpdate;
  };

  /* v fetch brands/favorite methods */
  const fetchIntegrationsAndAdAccountsForBrands = async (
    brandIds: string[],
    integrationTab: BrandTab,
  ) => {
    const brandId = brandIds.join(',');
    // fetch integrations for favorites
    const [integrationsResponse, adAccountBatchResponse] = await Promise.all([
      integrationsApi.getIntegrationsHistory({ brandId }),
      fbIntegrationsApi.getAdAccountsBatch({ brandId }),
    ]);

    // use callback syntax since state updates are asynchronous
    setIntegrations((prevIntegrations) => ({
      ...prevIntegrations,
      [integrationTab]: integrationsResponse.data as Integration[],
    }));

    const setAdAccountsForTab = {
      all: setAdAccounts,
      favorites: setFavoriteAdAccounts,
    };
    const setter = setAdAccountsForTab[integrationTab];
    setter(adAccountBatchResponse.data as AdAccountBatch);
  };

  const fetchFavorites = async () => {
    if (!userId) return;
    setLoading(true);
    try {
      const {
        data: { brandId: brandIds },
      } = await userFavoriteApi.getFavorites({ userId });

      const brandId = brandIds.join(',');

      // fetch integrations for favorites
      const [brandsResponse] = await Promise.all([
        brandsApi.getBrandBatch({ brandId }),
        fetchIntegrationsAndAdAccountsForBrands(brandIds, 'favorites'),
      ]);

      setFavorites(brandsResponse.data as Brand[]);
    } catch (error) {
      // TODO: handle error
    }
    setLoading(false);
  };

  // maps brands attributes to a neat object
  const processBrands = (brandsToProcess: Brand[]) => {
    const mappedBrands = brandsToProcess.map((brandData: Brand) => ({
      ...brandData, // @TODO: (Before merge to develop) Felix - For now return all data. After brand index is tested we can remove and purposely select plan and dailySpend columns.
      brandId: brandData.brandId,
      name: brandData.name,
      domain: brandData.domain || '',
      status: brandData.status,
      createdAt: brandData.createdAt,
      updatedAt: brandData.updatedAt,
    }));
    setBrands(mappedBrands);

    // scroll to top after fetching
    brandTableRef.current?.scrollTo({ top: 0 });
  };

  // fetches brands based on action taken
  const fetchBrands =
    (method: typeof getNextBrandBatch) =>
    async (keepLoading = false) => {
      const plans = Array.from(filteredPlans);
      // @TODO: Felix: Remove once ES brand index has been fixed in production
      if (plans.includes(PlanFilterOptions.Trial)) plans.push('freetrial');

      setLoading(true);
      const brandsBatch = await method({
        search: filterBrandName,
        plans: plans.join(','),
        spend: filteredSpend.join(','),
      });
      processBrands(brandsBatch);

      // fetch integrations for brands
      const brandIds = brandsBatch.map((b) => b.brandId);

      await fetchIntegrationsAndAdAccountsForBrands(brandIds as string[], 'all');
      setLoading(keepLoading || false);
    };

  // fetches brands based on search
  const fetchSearchedBrands = async () => {
    fetchBrands(getBrands)();
  };

  const fetchPrevious = async () => {
    if (hasPrevious) fetchBrands(getPreviousBrandBatch)();
  };

  const fetchNext = async () => {
    if (hasNext) fetchBrands(getNextBrandBatch)();
  };
  /* ^ end fetch brands/favorite methods */

  /* v delete brand methods */
  const confirmDeleteBrand = (brandId: string) => {
    setBrandIdToDelete(brandId);
  };

  const deleteBrandAndRefresh = async () => {
    try {
      setLoading(true);
      setBrandStatusUpdating(true);
      await brandsApi.deleteBrand({ brandId: brandIdToDelete });
      const activeBrands = brands.filter((b) => b.brandId !== brandIdToDelete);
      const activeFavorites = favorites.filter((f) => f.brandId !== brandIdToDelete);
      setBrands(activeBrands);
      setFavorites(activeFavorites);
      setBrandIdToDelete('');
    } catch (err) {
      /* TODO */
    }
    setLoading(false);
    setBrandStatusUpdating(false);
  };
  /* ^ end delete brand methods */

  /* v lock/unlock methods */
  const confirmLockBrand = (brandId: string) => {
    setBrandIdToLock(brandId);
  };

  const confirmUnlockBrand = (brandId: string) => {
    setBrandIdToUnlock(brandId);
  };

  const closeBrandLock = () => {
    setBrandIdToLock('');
  };

  const processAfterLockUnlockBrand = (action: 'lock' | 'unlock', brandId: string) => {
    const processedBrand = findBrandToUpdate(brandId);

    if (processedBrand) {
      const status = action === 'lock' ? BrandStatusEnum.Locked : BrandStatusEnum.Active;
      const updatedBrand = { ...processedBrand, status };

      const updatedBrands = brands.map((br) => (br.brandId === brandId ? updatedBrand : br));

      setBrands(updatedBrands);

      const brandInFavorites = favorites.find((b) => b.brandId === brandId);

      if (brandInFavorites) {
        const updatedFavorites = favorites.map((br) =>
          br.brandId === brandId ? updatedBrand : br,
        );

        setFavorites(updatedFavorites);
      }

      const message =
        action === 'lock'
          ? `Locked brand access for ${processedBrand?.name}`
          : `Unlocked brand access for ${processedBrand?.name}.`;

      showToaster({ message, variant: 'info' });
    }
  };

  const lockBrandAndRefresh = async (audienceOption: BrandLockAudienceOption) => {
    setLoading(true);
    setBrandStatusUpdating(true);
    try {
      await brandsApi.lockBrand({
        brandId: brandIdToLock,
        brandLockRequest: {
          audienceOption,
        },
      });

      processAfterLockUnlockBrand('lock', brandIdToLock);
      setBrandIdToLock('');
    } catch (err) {
      /* TODO */
    }
    setLoading(false);
    setBrandStatusUpdating(false);
  };

  const unlockBrandAndRefresh = async () => {
    setLoading(true);
    setBrandStatusUpdating(true);
    try {
      await brandsApi.unlockBrand({ brandId: brandIdToUnlock });

      processAfterLockUnlockBrand('unlock', brandIdToUnlock);
      setBrandIdToUnlock('');
    } catch (err) {
      /* TODO */
    }
    setLoading(false);
    setBrandStatusUpdating(false);
  };
  /* ^ end lock/unlock methods */

  const getIsAdAccountActive = (brandId: string | undefined) =>
    adAccounts.some((a) => a.brandId === brandId) ||
    favoriteAdAccounts.some((a) => a.brandId === brandId);

  brandListConfig.formatter = (row: Brand) => {
    const isAdAccountActive = getIsAdAccountActive(row.brandId);

    return {
      ...row,
      integrations: integrations[tab].filter((i) => i.brandId === row.brandId),
      loading,
      isSelectedBrand: brand?.brandId === row.brandId,
      refreshFavorites: fetchFavorites,
      confirmDeleteBrand,
      confirmLockBrand,
      confirmUnlockBrand,
      isAdAccountActive,
      favorites,
    };
  };

  const clearFilters = () => {
    setFilteredPlans([]);
    setFilteredSpend([]);
    setFiltersStateCounter(filterStateCounter + 1);
  };

  const onFilterBrands = (value: string) =>
    debouncer(() => {
      const isDeleted = searchDirty && !value.length;
      const isNewSearch = value.length >= MAX_CHARS_FOR_SEARCH || !value.length;
      const doSearch = isDeleted || isNewSearch;

      if (doSearch) {
        setSearchDirty(true);
        setFilterBrandName(value);
      }
    });

  const onFilterPlans = (selectedPlans: string[]) =>
    debouncer(() => {
      setSearchDirty(true);
      setFilteredPlans(selectedPlans);
    });

  const onFilterSpend = (selectedItems: string[]) =>
    debouncer(() => {
      setSearchDirty(true);
      setFilteredSpend(selectedItems);
    });

  // initial render
  useEffect(() => {
    const fetchBrandsAndFavorites = async () => {
      await fetchBrands(getNextBrandBatch)(true);
      await fetchFavorites();
    };

    fetchBrandsAndFavorites();
  }, []);

  // fetches brands on search input change
  useEffect(() => {
    if (isMounted.current) {
      if (searchDirty) fetchSearchedBrands();
    } else {
      isMounted.current = true;
    }
  }, [filterBrandName, filteredPlans, filteredSpend]);

  const brandToUnlock = findBrandToUpdate(brandIdToUnlock);
  const brandToLock = findBrandToUpdate(brandIdToLock);
  const brandToDelete = findBrandToUpdate(brandIdToDelete);

  useShowSpinner({ show: loading });

  const onPerformanceAdsClick = () => navigate('/creatives');

  return (
    <>
      <BrandListView
        onPerformanceAdsClick={onPerformanceAdsClick}
        hasSelectedFilters={hasSelectedFilters}
        filterStateCounter={filterStateCounter}
        clearFilters={clearFilters}
        loading={brandsLoading}
        brandTableRef={brandTableRef}
        brands={brands}
        favorites={favorites}
        config={brandListConfig}
        onFilterSpend={onFilterSpend}
        onFilterPlans={onFilterPlans}
        onFilterBrands={onFilterBrands}
        onNext={hasNext ? fetchNext : undefined}
        onPrevious={hasPrevious ? fetchPrevious : undefined}
        onTabChange={setTab}
        selectedTab={tab}
        tabs={tabs}
        isPerformanceAdsEnabled={performanceLibrary}
      />
      <DeleteConfirmation
        isLoading={brandStatusUpdating}
        confirm={deleteBrandAndRefresh}
        open={!!brandIdToDelete}
        close={() => setBrandIdToDelete('')}
        brandName={brandToDelete?.name || ''}
      />
      <LockConfirmation
        isLoading={brandStatusUpdating}
        isAdAccountActive={getIsAdAccountActive(brandIdToLock)}
        onConfirm={lockBrandAndRefresh}
        onClose={closeBrandLock}
        open={!!brandIdToLock}
        brand={brandToLock}
      />
      <UnlockConfirmation
        confirm={unlockBrandAndRefresh}
        open={!!brandIdToUnlock}
        close={() => setBrandIdToUnlock('')}
        brandName={brandToUnlock?.name || ''}
      />
    </>
  );
};

export default BrandList;
