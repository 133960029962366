import { TableConfig } from '@innovationdepartment/proxima-ui';
import { formatDateTimeFromIso } from 'utils/formatDate';
import BrandWarningsView from './BrandWarnings.View';
import { BrandWarning } from '@innovationdepartment/proxima-sdk-axios';
import { useBrandWarnings } from 'hooks';
import { useAdAccountStore, useIntegrationsStore } from 'stores';
import { useParams } from 'react-router-dom';
import { BrandListIntegrationStatusProps } from 'types/integrations';

const formatBrandWarning = (brandWarning: BrandWarning) => {
  const { createdAt } = brandWarning;
  return {
    ...brandWarning,
    createdAt: formatDateTimeFromIso(createdAt),
  };
};

const BrandWarnings = () => {
  const {
    brandWarnings,
    pageNumberBrandWarnings: pageNumber,
    paginationMapBrandWarnings,
    fetchBrandWarnings,
    loading,
  } = useBrandWarnings();
  const { brandId } = useParams();
  const { getAllIntegrations } = useIntegrationsStore();
  const activeIntegrations = getAllIntegrations();
  const { adAccount } = useAdAccountStore();

  const integrationsWithStatus = activeIntegrations.reduce(
    (acc, integration) => {
      if (!acc[integration.type]) acc[integration.type] = [];
      acc[integration.type].push(integration);
      return acc;
    },
    {} as BrandListIntegrationStatusProps['integrations'],
  );

  const brandWarningsConfig: TableConfig<BrandWarning> = {
    formatter: formatBrandWarning,
    columns: {
      order: ['message', 'createdAt'],
      message: { label: 'Warning' },
      createdAt: { label: 'Time' },
    },
  };

  const currPagination = paginationMapBrandWarnings[pageNumber];
  const hasNext = !!currPagination?.nextToken;
  const hasPrev = pageNumber !== 1;

  return (
    <BrandWarningsView
      brandId={brandId}
      adAccountId={adAccount?.accountId}
      integrationsWithStatus={integrationsWithStatus}
      loading={loading}
      hasNext={hasNext}
      hasPrev={hasPrev}
      handlePagination={(action: 'prev' | 'next') =>
        fetchBrandWarnings &&
        fetchBrandWarnings(action === 'next' ? currPagination.nextToken : currPagination.prevToken)
      }
      brandWarnings={brandWarnings}
      tableConfig={brandWarningsConfig}
    />
  );
};

export default BrandWarnings;
