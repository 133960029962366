import styled from '@emotion/styled';
import { MagicStars } from '@innovationdepartment/proxima-ui';
import * as dateFns from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAudiencesApi, useProximaSDK, useShowSpinner } from 'hooks';
import { useAdAccountStore, useBrandStore } from 'stores';
import { FlavorCategorySchema } from 'types/audiences';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import { BrandInsight, FlavorCategoryInsight } from '@innovationdepartment/proxima-sdk-axios';
import {
  InsightsTraditionalBenchmarks,
  InsightsNoMetaIntegration,
  InsightsSubheader,
} from './Layout';
import { InsightsInterval, InsightsMetric } from 'types/insights';
import { InsightsHeader } from '../SharedLayout';
import { META_BENCHMARKS_HELP_GUIDE_URL } from 'constants/urls';
import { checkFlavorCategoryValue } from '../insightsHelpers';

type QueryStringParams = {
  key: string;
  value: InsightsMetric | InsightsInterval | string;
};

const metricOptions = [
  {
    label: 'CPA',
    value: InsightsMetric.Cpa,
    icon: <MagicStars />,
  },
  {
    label: 'ROAS',
    value: InsightsMetric.Roas,
    icon: <MagicStars />,
  },
  {
    label: 'CTR',
    value: InsightsMetric.Ctr,
    icon: <MagicStars />,
  },
  {
    label: 'CPC',
    value: InsightsMetric.Cpc,
    icon: <MagicStars />,
  },
  {
    label: 'CPM',
    value: InsightsMetric.Cpm,
    icon: <MagicStars />,
  },
  {
    label: 'CVR',
    value: InsightsMetric.Cvr,
    icon: <MagicStars />,
  },
  {
    label: 'Spend',
    value: InsightsMetric.Spend,
    icon: <MagicStars />,
  },
];

const intervalOptions = [
  {
    label: 'By Month',
    value: InsightsInterval.Month,
  },
  {
    label: 'By Day',
    value: InsightsInterval.Day,
  },
  {
    label: 'By Quarter',
    value: InsightsInterval.Quarter,
  },
  {
    label: 'By Year',
    value: InsightsInterval.Year,
  },
  {
    label: 'By Week',
    value: InsightsInterval.Week,
  },
];

const ChartSection = styled.section`
  display: inline-block;
`;

const InsightsContainer = styled.div`
  background: linear-gradient(105deg, #e9fff9 0%, #e6edfe 100%);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InsightsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InsightsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Insights = () => {
  const [searchParams] = useSearchParams();
  const insightsApi = useProximaSDK('IntelligenceApi');

  const today = dateFns.startOfToday();
  const ninetyDaysAgo = dateFns.sub(today, { days: 90 }).toISOString();
  const endOfYesterday = dateFns.startOfDay(dateFns.endOfYesterday()).toISOString();

  const startDateFromSearchParams = dateFns.parseISO(
    searchParams?.get('startDate') || ninetyDaysAgo,
  );
  const endDateFromSearchParams = dateFns.parseISO(searchParams?.get('endDate') || endOfYesterday);

  // metrics toggle for each chart
  const [traditionalBenchmarksMetric, setTraditionalBenchmarksMetric] = useState<InsightsMetric>(
    InsightsMetric.Cpa,
  );
  // const [performanceDifferencesMetric, setPerformanceDifferencesMetric] = useState<InsightsMetric>(
  //   InsightsMetric.Cpa,
  // );

  // states for the query string params
  // const [anchorMetric, setAnchorMetric] = useState<InsightsMetric>(
  //   (searchParams.get('anchorMetric') as InsightsMetric) || InsightsMetric.Cpa,
  // );
  const [interval, setInterval] = useState<InsightsInterval>(
    (searchParams.get('interval') as InsightsInterval) || InsightsInterval.Week,
  );
  const [dateRange, setDateRange] = useState({
    startDate: startDateFromSearchParams,
    endDate: endDateFromSearchParams,
  });

  // states for the API calls
  const [flavorCategory, setFlavorCategory] = useState<FlavorCategorySchema>();

  const [tradBenchmarksFlavorCatInsights, setTradBenchmarksFlavorCatInsights] = useState<
    FlavorCategoryInsight[]
  >([]);
  const [tradBenchmarksBrandInsights, setTradBenchmarksBrandInsights] = useState<BrandInsight[]>(
    [],
  );

  // const [perfDiffFlavorCatInsights, setPerfDiffFlavorCatInsights] = useState<
  //   FlavorCatInsightsResponse[]
  // >([]);
  // const [perfDiffBrandInsights, setPerfDiffBrandInsights] = useState<BrandInsightsResponse[]>([]);

  const { adAccount } = useAdAccountStore();
  const { brand } = useBrandStore();

  const { getBrandFlavorCategory } = useAudiencesApi();

  const updateQueryStringParams = ({ key, value }: QueryStringParams) => {
    // Query string has to be updated like this because setSearchParams causes an unnecessary page re-render and seems to be a known bug: https://github.com/remix-run/react-router/discussions/9851
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, '', url.toString());
  };

  const fetchFlavorCategory = async () => {
    const response = await getBrandFlavorCategory();
    if (!response) return;

    setFlavorCategory(response);
  };

  // This is to fill in any missing values in the brand data for dates we have flavor category data for
  const fillBrandData = (
    brandInsights: BrandInsight[],
    flavorCategoryInsights: FlavorCategoryInsight[],
  ) => {
    const brandDates = new Map();

    brandInsights.forEach((brandData) => {
      brandDates.set(brandData.date, brandData);
    });

    return flavorCategoryInsights.map((flavorData) => {
      if (brandDates.has(flavorData.date)) return brandDates.get(flavorData.date);
      return { date: flavorData.date, value: 0 };
    });
  };

  const fetchTradBenchmarksData = async () => {
    // get brand insights
    if (adAccount) {
      const brandInsightsResponse = await insightsApi.getBrandInsights({
        brandId: brand.brandId,
        adAccountId: adAccount!.accountId,
        metric: traditionalBenchmarksMetric,
        interval,
        endDate: formatDateToIsoWithoutTime(dateRange.endDate),
        startDate: formatDateToIsoWithoutTime(dateRange.startDate),
      });
      const updatedBrandInsights = brandInsightsResponse.data;

      setTradBenchmarksBrandInsights(updatedBrandInsights);

      // get flavor category insights
      if (flavorCategory) {
        const flavorCatResponse = await insightsApi.getFlavorCategoryInsights({
          flavorCategoryId: checkFlavorCategoryValue(flavorCategory!.id),
          metric: traditionalBenchmarksMetric,
          anchorMetric: InsightsMetric.Cpa,
          interval,
          endDate: formatDateToIsoWithoutTime(dateRange.endDate),
          startDate: formatDateToIsoWithoutTime(dateRange.startDate),
        });
        let updatedFlavorCatInsights = flavorCatResponse.data;

        // CTR gets multiplied by 100 because the backend value returns as a decimal instead of the percentage
        if (traditionalBenchmarksMetric === InsightsMetric.Ctr)
          updatedFlavorCatInsights = flavorCatResponse.data.map((insight) => ({
            date: insight.date,
            rankings: insight.rankings?.map((ranking) => ({
              name: ranking.name,
              value: ranking.value! * 100,
            })),
          }));

        const roundedFlavorCat = updatedFlavorCatInsights.map((flavorCat) => ({
          ...flavorCat,
          rankings: flavorCat?.rankings?.map((ranking) => ({
            ...ranking,
            value: Number(ranking?.value?.toFixed(2)),
          })),
        }));

        setTradBenchmarksFlavorCatInsights(roundedFlavorCat);

        if (updatedBrandInsights.length !== updatedFlavorCatInsights.length) {
          const filledTradBenchmarksBrandData = fillBrandData(
            updatedBrandInsights,
            updatedFlavorCatInsights,
          );

          const roundedBrandInsights = filledTradBenchmarksBrandData.map((brandInsight) => ({
            ...brandInsight,
            value: Number(brandInsight.value.toFixed(2)),
          }));
          setTradBenchmarksBrandInsights(roundedBrandInsights!);
        }
      }
    }
  };

  // const fetchPerfDiffData = async () => {
  //   // get brand insights
  //   if (adAccount) {
  //     const brandInsightsResponse = await getBrandInsights({
  //       brandId: brand.brandId,
  //       adAccountId: adAccount!.accountId,
  //       metric: performanceDifferencesMetric,
  //       interval,
  //       endDate: formatDateToIsoWithoutTime(dateRange.endDate),
  //       startDate: formatDateToIsoWithoutTime(dateRange.startDate),
  //     });
  //     const updatedBrandInsights = brandInsightsResponse.data;

  //     setPerfDiffBrandInsights(updatedBrandInsights);

  //     // get flavor category insights
  //     if (flavorCategory) {
  //       const flavorCatResponse = await getFlavorCatInsights({
  //         flavorCategoryId: flavorCategory!.id,
  //         metric: performanceDifferencesMetric,
  //         anchorMetric,
  //         interval,
  //         endDate: formatDateToIsoWithoutTime(dateRange.endDate),
  //         startDate: formatDateToIsoWithoutTime(dateRange.startDate),
  //       });
  //       const updatedFlavorCatInsights = flavorCatResponse.data;

  //       setPerfDiffFlavorCatInsights(updatedFlavorCatInsights);

  //       if (updatedBrandInsights.length !== updatedFlavorCatInsights.length) {
  //         const filledFlavorCatBrandData = fillBrandData(
  //           perfDiffBrandInsights,
  //           updatedFlavorCatInsights,
  //         );
  //         setPerfDiffBrandInsights(filledFlavorCatBrandData!);
  //       }
  //     }
  //   }
  // };

  // const onAnchorMetricChanged = (newAnchorMetric: InsightsMetric) => {
  //   setAnchorMetric(newAnchorMetric);
  //   updateQueryStringParams({ key: 'anchorMetric', value: newAnchorMetric });
  // };

  const onIntervalChanged = (newInterval: InsightsInterval) => {
    setInterval(newInterval);
    updateQueryStringParams({ key: 'interval', value: newInterval });
  };

  const onDateRangeSelect = (newDateRange: any) => {
    setDateRange(newDateRange);
    updateQueryStringParams({
      key: 'startDate',
      value: formatDateToIsoWithoutTime(newDateRange.startDate),
    });
    updateQueryStringParams({
      key: 'endDate',
      value: formatDateToIsoWithoutTime(newDateRange.endDate),
    });
  };

  const traditionalBenchmarksHighchartOptions = {
    brandInsights: tradBenchmarksBrandInsights,
    flavorCatInsights: tradBenchmarksFlavorCatInsights,
  };
  // const performanceDifferencesHighchartOptions = {
  //   brandInsights: perfDiffBrandInsights,
  //   flavorCatInsights: perfDiffFlavorCatInsights,
  // };

  // const anchorOptionLabel = metricOptions.find((option) => option.value === anchorMetric)?.label!;

  useEffect(() => {
    fetchFlavorCategory();
  }, []);

  useEffect(() => {
    fetchTradBenchmarksData();
  }, [flavorCategory, traditionalBenchmarksMetric, interval, dateRange]);

  // useEffect(() => {
  //   fetchPerfDiffData();
  // }, [flavorCategory, performanceDifferencesMetric, anchorMetric, interval, dateRange]);

  useShowSpinner({ show: insightsApi.loading });

  return (
    <InsightsContainer>
      {!adAccount && <InsightsNoMetaIntegration />}
      <InsightsStickyTop>
        <InsightsHeader
          brandName={brand.name}
          helpLink={META_BENCHMARKS_HELP_GUIDE_URL}
          title="Benchmarks | Meta"
        />
        <InsightsSubheader
          category={flavorCategory?.name || ''}
          dateRange={dateRange}
          intervalOptions={intervalOptions}
          interval={interval}
          onDateRangeSelect={onDateRangeSelect}
          onIntervalChanged={onIntervalChanged}
        />
      </InsightsStickyTop>
      <InsightsInnerContainer>
        <ChartSection>
          <InsightsTraditionalBenchmarks
            currentMetric={traditionalBenchmarksMetric}
            metricsOptions={metricOptions}
            chartOptions={traditionalBenchmarksHighchartOptions}
            onMetricChanged={setTraditionalBenchmarksMetric}
          />
        </ChartSection>
        {/* TODO (Milecia): leaving this commented out because we'll likely add this back at some point. */}
        {/* <ChartSection>
          <InsightsPerformanceDifferences
            currentMetric={performanceDifferencesMetric}
            anchorMetric={anchorOptionLabel}
            metricsOptions={metricOptions}
            chartOptions={performanceDifferencesHighchartOptions}
            onMetricChanged={setPerformanceDifferencesMetric}
          />
        </ChartSection> */}
      </InsightsInnerContainer>
    </InsightsContainer>
  );
};

export default Insights;
