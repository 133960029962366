import { useEffect, useState } from 'react';

import { useAdManager, useProximaSDK, useShowSpinner } from 'hooks';
import { useBrandStore, useIntegrationsStore } from 'stores';

import { Integration } from 'types/integrations';
import { useNavigate } from 'react-router-dom';

const IntegrationsStoreLoader: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { loading: adAccountLoading, refreshAdAccount } = useAdManager();
  const integrationStore = useIntegrationsStore();
  const integrationsApi = useProximaSDK('IntegrationsApi');
  const { brand } = useBrandStore();

  const allLoading = integrationsApi.loading || adAccountLoading;

  useShowSpinner({ show: allLoading });

  useEffect(() => {
    if (!brand?.brandId) {
      navigate('/brands');
      return;
    }

    const fetchIntegrations = async () => {
      integrationStore.clear();

      const { brandId } = brand;

      const response = await integrationsApi.getIntegrationsHistoryForBrand({ brandId });

      if (response.status !== 200) return;

      const integrations = response.data ?? [];

      // If we have integrations set them in the store
      integrationStore.addIntegration(integrations as Integration[]);
    };

    if (brand.brandId) {
      Promise.all([fetchIntegrations(), refreshAdAccount({ brandId: brand.brandId })]).then(() =>
        setLoaded(true),
      );
    }
  }, [brand?.brandId]);

  if (!loaded) return <></>;

  return <>{children}</>;
};

export default IntegrationsStoreLoader;
