import { create } from 'zustand';
import pick from 'lodash/pick';

import {
  AdAccount,
  AdAccountStatus,
  AdAccountStore,
  allowedKeys,
} from 'types/stores/ad-account-store';

const useAdAccountStore = create<AdAccountStore>((set, get) => ({
  adAccount: null,
  clear: () => set({ adAccount: null, adAccountsList: [] }),
  update: (adAccount: Partial<AdAccount>) =>
    set((prevState) => {
      const newAdAccount = pick(adAccount, allowedKeys) as AdAccount;
      const store: Partial<AdAccountStore> = { adAccount: newAdAccount };
      if (prevState.adAccount) store.adAccount = { ...prevState.adAccount, ...newAdAccount };
      return store;
    }),
  // list values
  adAccountsList: [],
  getActiveAdAccountFromList: () =>
    get().adAccountsList.find((adAccount) => adAccount.status === AdAccountStatus.Active),
  updateAdAcountsList: (adAccountsList: AdAccount[]) => set({ adAccountsList }),
}));

export default useAdAccountStore;
