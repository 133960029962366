import { useAdAccountStore, useIntegrationsStore } from 'stores';
import { IntegrationStatus } from 'types/stores/integration-store';
import { BrandIntegrationStatus, IntegrationType } from 'types/integrations';

const useFacebook = () => {
  const adAccountStore = useAdAccountStore();
  const integrationsStore = useIntegrationsStore();

  const getMetaStatus = () => {
    const fbIntegrations = integrationsStore.getAllIntegrations({ type: IntegrationType.Facebook });
    const neverConnected = fbIntegrations.length === 0;
    if (neverConnected) return BrandIntegrationStatus.NeverConnected;
    const hasActiveFBIntegration = fbIntegrations.some(
      (integration) => integration.status === IntegrationStatus.Active,
    );
    const hasAdAccount = adAccountStore.adAccount?.accountId;
    const isConnected = hasActiveFBIntegration && hasAdAccount;
    const isIncomplete = hasActiveFBIntegration && !hasAdAccount;
    if (isConnected) {
      return BrandIntegrationStatus.Connected;
    }
    if (isIncomplete) return BrandIntegrationStatus.Incomplete;
    return BrandIntegrationStatus.Disconnected;
  };

  return {
    getMetaStatus,
  };
};

export default useFacebook;
