import { TableConfig, TableData } from '@innovationdepartment/proxima-ui';
import { Integration } from './integrations';

export type SearchableBrandType = {
  name: string;
  id: string;
};

export enum PlanFilterOptions {
  Trial = 'freeTrial',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Pro = 'pro',
}

export enum SpendActivityFilterOptions {
  Spending = 'true',
  'Not Spending' = 'false',
}

export type BrandTab = 'all' | 'favorites';

type TabProps = { label: string; key: BrandTab };

export type BrandListIntegrations = {
  [key in BrandTab]: Integration[];
};

export type BrandListProps<T> = {
  brandTableRef: React.RefObject<HTMLDivElement>;
  onFilterBrands: (name: string) => void;
  onFilterPlans: (selectedPlans: string[]) => void;
  onFilterSpend: (selectedItems: string[]) => void;
  clearFilters: () => void;
  hasSelectedFilters: boolean;
  filterStateCounter: number;
  tabs: TabProps[];
  selectedTab: BrandTab;
  brands: T;
  favorites: T;
  onTabChange: (key: BrandTab) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onPerformanceAdsClick: () => void;
  loading: boolean;
  isPerformanceAdsEnabled?: boolean;
  config: TableConfig<T extends TableData ? T : TableData>; // https://github.com/InnovationDepartment/proxima.web.components/blob/main/src/lib/Table/README.md
};
