import React from 'react';
import { FacebookSmall, ShopifySmall, TikTokSmall } from '@innovationdepartment/proxima-ui';
import { IntegrationType } from 'types/integrations';
import { LookalikeAudienceTableRowProps, AudienceTableCellRendererProps } from 'types/audiences';
import VerticalWrapper from './AudienceRowCell.VerticalWrapper';

const PLATFORMS: { [key in IntegrationType]: React.ReactNode } = {
  [IntegrationType.Facebook]: <FacebookSmall />,
  [IntegrationType.TikTok]: <TikTokSmall />,
  [IntegrationType.Shopify]: <ShopifySmall />,
};

const AudienceRowCellPlatform = ({
  row,
}: AudienceTableCellRendererProps<LookalikeAudienceTableRowProps>) => (
  <VerticalWrapper>{row?.integrationType ? PLATFORMS[row?.integrationType] : null}</VerticalWrapper>
);

export default AudienceRowCellPlatform;
