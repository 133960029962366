import styled from '@emotion/styled';
import { AllowedColors, Button, colors, DatePicker } from '@innovationdepartment/proxima-ui';
import HorizontalNavbar from 'ui/HorizontalNavbar';
import PerformanceAdsTable from './Layout/PerformanceAdsTable';
import FiltersHeaderBarControls from './Layout/Filters/FiltersHeaderBarControls';
import { PerformanceAdsViewProps } from './types';
import BrandFilter from './Layout/Filters/BrandFilter';

const ContentWrapper = styled.div`
  nav > button {
    border-color: ${colors.white};
    &:hover {
      background-color: ${colors.neutral100};
    }

    & * {
      color: ${colors.white};
    }
  }
`;

const TableWrapper = styled.div`
  height: calc(100vh - 68px - 65px);

  /* TODO(Jenky): remove this wrapper once table styles are fixed or provides custom styling */

  table :is(th, tr) {
    /* name column */
    & td:first-of-type {
      max-width: 800px;
    }
  }
`;

const PerformanceAdsView = (props: PerformanceAdsViewProps) => {
  const {
    onGoBackClick,
    showFilters,
    onShowFilters,
    onApplyFilters,
    dateRange,
    onDateRangeChange,
  } = props;
  return (
    <ContentWrapper>
      <HorizontalNavbar label="Creative Perfomance Library">
        <Button
          overrideColors={{
            backgroundColor: colors.neutral100,
            textColor: colors.white as AllowedColors,
          }}
          variant="outlined"
          color="secondary"
          label="Back to Nova"
          onClick={onGoBackClick}
        />
      </HorizontalNavbar>
      <FiltersHeaderBarControls>
        <BrandFilter
          open={showFilters.brand}
          onSaveSelection={(selected) => onApplyFilters({ brandId: selected })}
          onOpen={() => onShowFilters('brand')}
          onClose={() => onShowFilters('brand', false)}
        />
        <DatePicker
          disableFutureDates
          dateRange={dateRange}
          withRange
          showSelectWithDateRange={false}
          onDateRangeSelect={onDateRangeChange}
        />
      </FiltersHeaderBarControls>
      <TableWrapper>
        <PerformanceAdsTable {...props} />
      </TableWrapper>
    </ContentWrapper>
  );
};

export default PerformanceAdsView;
