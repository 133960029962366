import { Tabs } from '@innovationdepartment/proxima-ui';
import { useEffect, useState } from 'react';
import { PropsOf } from '@emotion/react';
import { CreateSeedAudiencesModalProps, Tab } from 'types/audiences';
import { useAdManager } from 'hooks';
import CreateSeedAudiencesModalView from './CreateSeedAudiencesModal.View';

const TABS: PropsOf<typeof Tabs>['tabs'] = [
  { key: 'categoryShoppers', label: 'Category shoppers' },
  { key: 'colal', label: 'COLAL' },
];

const CreateSeedAudiencesModal = (props: CreateSeedAudiencesModalProps) => {
  const { show } = props;
  const [tab, setTab] = useState<Tab>('categoryShoppers');
  const { getAdAccount } = useAdManager();

  const onTabChange = (nextTab: Tab) => {
    setTab(nextTab);
  };

  useEffect(() => {
    /* reset tab state */
    if (show) setTab('categoryShoppers');
    /* fetches ad account in case is not available on loading */
    if (show) getAdAccount();
  }, [show]);

  return (
    <CreateSeedAudiencesModalView {...props} tab={tab} tabs={TABS} onTabChange={onTabChange} />
  );
};

export default CreateSeedAudiencesModal;
