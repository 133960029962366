import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerformanceAdsView from './PerformanceAds.View';
import { usePerformanceAdManager } from 'hooks';

type Filters = {
  brand: boolean;
};

const PerformanceAds = () => {
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState<Filters>({ brand: false });
  const { ads, loaded, loading, onRequestNext, onApplyFilters, dateRange, onDateRangeChange } =
    usePerformanceAdManager();

  const onGoBackClick = () => navigate('/brands');

  /* opens/closes filters */
  const onFilterShow = (filter: keyof Filters, show: boolean = true) => {
    setShowFilters((prev) => ({ ...prev, [filter]: show }));
  };

  return (
    <PerformanceAdsView
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
      onApplyFilters={onApplyFilters}
      onShowFilters={onFilterShow}
      showFilters={showFilters}
      onGoBackClick={onGoBackClick}
      ads={ads}
      loaded={loaded}
      loading={loading}
      onRequestNext={onRequestNext}
    />
  );
};

export default PerformanceAds;
