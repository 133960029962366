import { useMemo, useState } from 'react';
import { FBTableTabs } from 'types/fbTable';
import { useAdManager, useAdManagerNavigation, useShowSpinner } from 'hooks';
import { useAdAccountStore } from 'stores';
import FacebookTableView from './FacebookTable.View';

const FacebookTableContainer = () => {
  const { adAccount } = useAdAccountStore();
  const { getCampaigns, getAdSets, getAds } = useAdManager();
  const [tab, setTab] = useState<FBTableTabs>('campaigns');

  const getMethods = useMemo(
    () => ({
      ads: getAds,
      adsets: getAdSets,
      campaigns: getCampaigns,
    }),
    [],
  );

  const {
    ads,
    adsets,
    campaigns,
    dateRange,
    lastUpdate,
    loaded,
    loading,
    onDateRangeChange,
    onPaginateNext,
    onPaginatePrevious,
    onRowSelect,
    pages,
  } = useAdManagerNavigation(tab, getMethods);

  useShowSpinner({ show: loading });

  const data = {
    campaigns,
    adsets,
    ads,
  };

  const { hasNext, hasPrevious } = pages[tab];

  return (
    <FacebookTableView
      adAccount={adAccount}
      loading={loaded && loading}
      initialLoading={!loaded}
      lastUpdate={lastUpdate}
      dateRange={dateRange}
      onDateRangeChange={onDateRangeChange}
      tab={tab}
      onRowSelect={onRowSelect}
      onTabClick={setTab}
      campaigns={data.campaigns}
      adsets={data.adsets}
      ads={data.ads}
      onNext={hasNext ? () => onPaginateNext(tab) : undefined}
      onPrevious={hasPrevious ? () => onPaginatePrevious(tab) : undefined}
    />
  );
};

export default FacebookTableContainer;
