import styled from '@emotion/styled';
import { BrandListIntegrationStatusProps, IntegrationType } from 'types/integrations';
import LoggedInAsBrandBadge from 'ui/LoggedInAsBrandBadge';
import BrandOverviewHeaderItem from './BrandOverview.HeaderItem.Container';
import BrandListIntegrationStatus from 'components/BrandList/BrandListIntegrations/BrandListIntegrationStatus';

const BrandOverviewHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 16px;
`;

type BrandOverviewHeaderProps = {
  integrations: BrandListIntegrationStatusProps['integrations'];
  brandId?: string;
  adAccountId?: string;
};

const IntegrationsWrapper = styled.div`
  position: relative;
  gap: 4px;
  display: flex;
`;

const BrandOverviewHeader = ({ integrations, adAccountId, brandId }: BrandOverviewHeaderProps) => {
  const integrationsContent = (
    <IntegrationsWrapper>
      <BrandListIntegrationStatus
        includeIndicator
        brandId={brandId as string}
        integrations={integrations}
        type={IntegrationType.Facebook}
        isAdAccountActive={Boolean(adAccountId)}
      />
      <BrandListIntegrationStatus
        includeIndicator
        brandId={brandId as string}
        integrations={integrations}
        type={IntegrationType.Shopify}
      />
    </IntegrationsWrapper>
  );
  return (
    <BrandOverviewHeaderWrapper>
      <LoggedInAsBrandBadge />
      {adAccountId && (
        <BrandOverviewHeaderItem
          canCopy
          label="Ad account ID"
          content={adAccountId.replace('act_', '')}
        />
      )}
      {brandId && <BrandOverviewHeaderItem canCopy label="Proxima ID" content={brandId} />}
      <BrandOverviewHeaderItem top={8} label="Integrations" content={integrationsContent} />
    </BrandOverviewHeaderWrapper>
  );
};

export default BrandOverviewHeader;
