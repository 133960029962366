import * as date from 'date-fns';
import { PropsOf } from '@emotion/react';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import {
  AdData,
  AdsetData,
  CampaignData,
  FBTableTabs,
  PageSettings,
  PAGE_DEFAULT_SIZE,
  PaginationSettings,
} from 'types/fbTable';
import { useAdManager } from 'hooks';
import FacebookTableSearchFilter from 'components/FacebookTable/FacebookTableSearchFilter';
import { IApiResponse } from 'types/hooks/useHandleApi';
import _ from 'lodash';

type PaginateOptions = {
  whichTab: FBTableTabs;
  direction: 1 | -1;
  opts?: PaginationSettings;
};

type GetMethods = {
  [key in FBTableTabs]?: (opts: PaginationSettings) => Promise<IApiResponse<any>>;
};

type AdManagerNavigationOpts = {
  skipLastUpdate?: boolean;
};

const yesterday = date.startOfDay(date.endOfYesterday());
const on90DaysAgo = date.sub(yesterday, { days: 89 });
const DEFAULT_DATE_RANGE = { endDate: yesterday, startDate: on90DaysAgo };

const useAdManagerNavigation = (
  tab: FBTableTabs,
  getMethods: GetMethods,
  { skipLastUpdate = false }: AdManagerNavigationOpts = {},
) => {
  const { getLastUpdatedAt, loading: adManagerLoading } = useAdManager();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<Record<FBTableTabs, PageSettings>>({
    campaigns: {
      hasNext: true,
      hasPrevious: false,
      page: 0,
    },
    ads: {
      hasNext: true,
      hasPrevious: false,
      page: 0,
    },
    adsets: {
      hasNext: true,
      hasPrevious: false,
      page: 0,
    },
  });
  const [lastUpdate, setLastUpdate] = useState<string>();
  const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
  const [selectedAdsets, setSelectedAdsets] = useState<any[]>([]);
  const [selectedAds, setSelectedAds] = useState<any[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignData>({ data: [], summary: undefined });
  const [adsets, setAdsets] = useState<AdsetData>({ data: [], summary: undefined });
  const [ads, setAds] = useState<AdData>({ data: [], summary: undefined });
  const [dateRange, setDateRange] =
    useState<PropsOf<typeof FacebookTableSearchFilter>['dateRange']>(DEFAULT_DATE_RANGE);
  const [previousDates, setPreviousDates] = useState<typeof dateRange>(dateRange);

  const setData = {
    campaigns: setCampaigns,
    adsets: setAdsets,
    ads: setAds,
  };

  const setSelected = {
    campaigns: setSelectedCampaigns,
    adsets: setSelectedAdsets,
    ads: setSelectedAds,
  };

  const selected = {
    campaigns: selectedCampaigns,
    adsets: selectedAdsets,
    ads: selectedAds,
  };

  const getSelectedItems = (whichTab: FBTableTabs) => {
    const checkIfCampaignShouldRequestData = () => {
      if (JSON.stringify(previousDates) !== JSON.stringify(dateRange)) {
        setPreviousDates(dateRange);
        return {};
      }
      return undefined;
    };
    const checkIfShouldPaginate = (whichToVerify: FBTableTabs) => {
      const selectedRows = selected[whichToVerify];
      if (selectedRows.length) {
        const ids = selectedRows.map((item) => item.id);
        const tabKey = pluralize.singular(whichToVerify);
        return { [`${tabKey}Ids`]: ids } as Record<string, string[]>;
      }
      // TODO(Jenky): This logic shouldn't unselect already selected in tab
      return {};
    };

    if (whichTab === 'campaigns') return checkIfCampaignShouldRequestData();
    if (whichTab === 'adsets') return checkIfShouldPaginate('campaigns');
    if (whichTab === 'ads') {
      // I liked this better :c... I guess figure out previous TODO first :\
      // return checkIfShouldPaginate('adsets') ?? checkIfShouldPaginate('campaigns');
      const queryItems = checkIfShouldPaginate('adsets');
      if (Object.keys(queryItems).length) return queryItems;
      return checkIfShouldPaginate('campaigns');
    }
    return undefined;
  };

  const onPaginate = async ({ whichTab, direction, opts }: PaginateOptions) => {
    const method = getMethods[whichTab];
    if (!method) return;
    setLoading(true);
    const pageSetting = pages[whichTab];
    const page = opts?.pageNumber ?? pageSetting.page + direction;
    const queryItems = { ...opts?.queryItems };

    if (dateRange.startDate) queryItems.startDate = date.format(dateRange.startDate, 'yyyy-MM-dd');
    if (dateRange.endDate) queryItems.endDate = date.format(dateRange.endDate, 'yyyy-MM-dd');

    const response = await method({ pageNumber: page, queryItems });

    const { [whichTab]: objectData, pageSize, pageNumber, summary } = response.data;

    const tabSettings = {
      hasNext: pageSize === PAGE_DEFAULT_SIZE,
      hasPrevious: pageNumber > 1,
      page,
    };

    setPages((allPages) => ({ ...allPages, [whichTab]: tabSettings }));

    setData[whichTab]({ data: objectData, summary });
    setLoading(false);
  };

  const onPaginateNext = async (whichTab: FBTableTabs, queryOpts?: PaginationSettings) => {
    const queryItems = getSelectedItems(whichTab);
    onPaginate({ whichTab, direction: 1, opts: _.merge({ queryItems }, queryOpts) });
  };

  const onPaginatePrevious = async (whichTab: FBTableTabs) => {
    const queryItems = getSelectedItems(whichTab);
    onPaginate({ whichTab, direction: -1, opts: { queryItems } });
  };

  const onRowSelect = (whichTab: FBTableTabs, selectedRows: any[]) => {
    setSelected[whichTab](selectedRows);
  };

  const onDateRangeChange = (newDateRange: typeof dateRange) => {
    setDateRange(newDateRange);
  };

  useEffect(() => {
    const getLastUpdate = async () => {
      const updatedAt = await getLastUpdatedAt();

      if (updatedAt) setLastUpdate(updatedAt);
    };
    const initialize = async () => {
      await onPaginateNext(tab);
      if (!skipLastUpdate) await getLastUpdate();
      setLoaded(true);
    };
    initialize();
  }, []);

  useEffect(() => {
    if (!loaded) return;
    const queryItems = getSelectedItems(tab);
    if (queryItems) {
      onPaginate({
        direction: 1,
        whichTab: tab,
        opts: {
          pageNumber: 1,
          queryItems,
        },
      });
    }
  }, [tab]);

  // on date change, request new data for that date range on the current tab
  useEffect(() => {
    if (loaded) {
      // include selected items in query
      const queryItems = getSelectedItems(tab);
      onPaginate({ whichTab: tab, direction: 1, opts: { pageNumber: 1, queryItems } });
    }
  }, [dateRange]);

  const isLoading = loading || adManagerLoading;

  return {
    loading: isLoading,
    dateRange,
    loaded,
    lastUpdate,
    campaigns,
    adsets,
    ads,
    onPaginateNext,
    onPaginatePrevious,
    onRowSelect,
    onDateRangeChange,
    pages,
  };
};

export default useAdManagerNavigation;
